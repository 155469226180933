<template>
    <div class="login-container">
        <div class="divider"></div>
        <div class="title">Login to your account</div>
        <div class="subtitle">
            Enter your phone number and verification code to get started.
        </div>
        <a-tabs class="login-form" :default-active-key="showTab"  size="large" type="line" :tabBarGutter="0">
            <a-tab-pane :key="1" tab="Customer">
                <a-form-model class="sms-login" :model="loginForm">
                    <a-form-model-item>
                        <a-input
                            v-model="loginForm.mobile_phone"
                            size="large"
                            placeholder="Mobile Phone Number"
                            v-decorator="[
                                'phone',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message:
                                                'Please input your phone number!'
                                        }
                                    ]
                                }
                            ]"
                            style="width: 100%"
                        >
                            <a-select
                                v-model="loginForm.nation_code"
                                slot="addonBefore"
                                v-decorator="['prefix', { initialValue: '86' }]"
                                style="width: 80px"
                            >
                                <a-select-option value="64">
                                    +64
                                </a-select-option>
                                <a-select-option value="86">
                                    +86
                                </a-select-option>
                                <a-select-option value="61">
                                    +61
                                </a-select-option>
                            </a-select>
                        </a-input>
                    </a-form-model-item>
                    <a-form-model-item>
                        <a-row :gutter="10" class="account-type">
                            <a-col :span="8">
                                <span>Account Type</span>
                            </a-col>
                            <a-col :span="16">
                                <a-radio-group v-model="loginForm.type">
                                    <a-radio class="account-type" value="I">
                                        Individual
                                    </a-radio>
                                    <a-radio class="account-type" value="E">
                                        Entity
                                    </a-radio>
                                </a-radio-group>
                            </a-col>
                        </a-row>
                    </a-form-model-item>
                    <a-form-model-item>
                        <a-row :gutter="10">
                            <a-col :span="codeCol">
                                <a-input
                                    v-model="loginForm.verify_code"
                                    size="large"
                                    :maxLength="6"
                                    placeholder="Verification Code"
                                    v-decorator="[
                                        'captcha',
                                        {
                                            rules: [
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input the captcha you got!'
                                                }
                                            ]
                                        }
                                    ]"
                                />
                            </a-col>
                            <a-col :span="buttonCol">
                                <a-button
                                    size="large"
                                    type="primary"
                                    @click="getVerifyCode"
                                    :disabled="isGetCodeButtonDisable"
                                    class="sms_button"
                                    >{{ getCodeButtonTitle }}</a-button
                                >
                            </a-col>
                        </a-row>
                    </a-form-model-item>
                    <button
                    :disabled='loginBtnDisable'
                    @click="mobileLogin"
                    class="sub-btn margintop20"
                    >LOGIN</button>
                </a-form-model>
            </a-tab-pane>
            <a-tab-pane :key="2" tab="Referrer">
                <a-form-model class="sms-login" :model="brokerLoginForm">
                    <a-form-model-item>
                        <a-input
                            v-model="brokerLoginForm.mobile_phone"
                            size="large"
                            placeholder="Mobile Phone Number"
                            v-decorator="[
                                'phone',
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message:
                                                'Please input your phone number!'
                                        }
                                    ]
                                }
                            ]"
                            style="width: 100%"
                        >
                            <a-select
                                v-model="brokerLoginForm.nation_code"
                                slot="addonBefore"
                                v-decorator="['prefix', { initialValue: '86' }]"
                                style="width: 80px"
                            >
                                <a-select-option value="64">
                                    +64
                                </a-select-option>
                                <a-select-option value="86">
                                    +86
                                </a-select-option>
                                <a-select-option value="61">
                                    +61
                                </a-select-option>
                            </a-select>
                        </a-input>
                    </a-form-model-item>
                    <a-form-model-item>
                        <a-row :gutter="10">
                            <a-col :span="codeCol">
                                <a-input
                                    v-model="brokerLoginForm.verify_code"
                                    size="large"
                                    :maxLength="6"
                                    placeholder="Verification Code"
                                    v-decorator="[
                                        'captcha',
                                        {
                                            rules: [
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input the captcha you got!'
                                                }
                                            ]
                                        }
                                    ]"
                                />
                            </a-col>
                            <a-col :span="buttonCol">
                                <a-button
                                    size="large"
                                    type="primary"
                                    @click="getBrokerVerifyCode"
                                    :disabled="isBrokerGetCodeButtonDisable"
                                    style="width: 100%; height: 46px"
                                    >{{ getCodeButtonTitle }}</a-button
                                >
                            </a-col>
                        </a-row>
                    </a-form-model-item>
                    <button
                    :disabled="brokerLoginBtnDisabled"
                    @click="brokerMobileLogin"
                    class="sub-btn margintop20"
                    >LOGIN</button>
                </a-form-model>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
import { getToken, awsGetToken, smsCodeLogin, sendVerifyCode, brokerSendVerifyCode, brokerSmsCodeLogin } from '@/api/common'
import { mapActions, mapState } from 'vuex'
import { setLocalStorage, getLocalStorage } from '@/utils/localStorage'
/* eslint-disable prefer-promise-reject-errors */

export default {
  data () {
    return {
      referrer: null,
      source: null,
      loginBtnDisable: false,
      brokerLoginBtnDisabled: false,
      loginForm: {
        nation_code: process.env.VUE_APP_BASE_PHONE_SC,
        mobile_phone: '',
        verify_code: '',
        type: 'I'
      },
      brokerLoginForm: {
        nation_code: process.env.VUE_APP_BASE_PHONE_SC,
        mobile_phone: '',
        verify_code: ''
      },
      isGetCodeButtonDisable: false,
      isBrokerGetCodeButtonDisable: false,
      getCodeButtonTitle: 'Get Code',
      codeCol: 16,
      buttonCol: 8,
      showTab: 1
    }
  },
  created () {
    if (this.showReferrerLogin) {
      this.showTab = 2
    }
    const referrer = this.$route.query.referrer || this.$route.query.id || null
    this.source = this.$route.query.source || null

    if (referrer) {
      this.referrer = referrer
    }
    this.closeMaskAction()
    const tokenData = getLocalStorage('token')
    if (tokenData) {
      this.$router.push({ path: '/user' })
      return true
    }

    this.checkMobile()
  },
  computed: {
    ...mapState(['showReferrerLogin']),
    isAllValid () {
      return this.username_valid && this.password_valid
    }
  },
  components: {},
  methods: {
    ...mapActions('login', [
      'SetTokenAction',
      'SetHasLogInAction',
      'setUserDetailProfileAction',
      'SetAwsAction',
      'SetStatusAction'
    ]),
    ...mapActions('mask', [
      'closeMaskAction'
    ]),
    handleLoginClick () {
      getToken()
    },
    handleSignClick () {
      this.$router.push('/signup')
    },
    checkMobile () {
      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        this.codeCol = 14
        this.buttonCol = 10
      }
    },
    mobileLogin () {
      this.loginBtnDisable = true
      if (this.getCodeClick(this.loginForm)) {
        const query = {
          nation: this.loginForm.nation_code,
          phone: this.loginForm.mobile_phone.trim(),
          code: this.loginForm.verify_code,
          type: this.loginForm.type
        }
        smsCodeLogin(query)
          .then((res) => {
            setLocalStorage('token', { ...res.token, ...res.user })
            setLocalStorage('user', res.user)
            this.$message.success('Login successfully!')
            this.$router.push({ path: '/user' })
            this.loginBtnDisable = false
          })
          .then(() => {
            awsGetToken().then((res) => {
              this.SetAwsAction(res)
            })
          })
          .catch((e) => {
            this.$message.error('Login failed!')
            setTimeout(() => {
              this.loginBtnDisable = false
            }, 2000)
          })
      } else {
        this.$message.error('Wrong moblie phone number, please check!')
        this.loginBtnDisable = false
      }
    },
    setTimeCount (num) {
      if (num) {
        var timer = setInterval(() => {
          num--
          this.getCodeButtonTitle = num + 's, reacquire'
          if (num === 0) {
            this.getCodeButtonTitle = 'Get Code'
            this.isGetCodeButtonDisable = false
            this.isBrokerGetCodeButtonDisable = false
            clearInterval(timer)
          }
        }, 1000)
      }
    },
    getCodeClick (form) {
      if (form.mobile_phone == null) {
        return false
      } else if (form.nation_code === '86' && !/^1[3-8]\d{9}$/.test(form.mobile_phone.trim())) {
        return false
      } else if (form.mobile_phone.trim().length < 8) {
        return false
      }
      return true
    },
    getVerifyCode () {
      this.isGetCodeButtonDisable = true
      this.isBrokerGetCodeButtonDisable = true
      if (this.getCodeClick(this.loginForm)) {
        const query = {
          phone: this.loginForm.mobile_phone.trim(),
          nation: this.loginForm.nation_code,
          type: this.loginForm.type,
          referrer: this.referrer,
          source: this.source
        }
        sendVerifyCode(query)
          .then((res) => {
            this.$message.success('Verification code has been sent')
            this.setTimeCount(60)
          })
          .catch(() => {
            this.$message.error('Service is Busy')
            this.setTimeCount(5)
          })
      } else {
        this.$message.error('Wrong moblie phone number, please check!')
        this.isGetCodeButtonDisable = false
        this.isBrokerGetCodeButtonDisable = false
      }
    },
    getBrokerVerifyCode () {
      this.isGetCodeButtonDisable = true
      this.isBrokerGetCodeButtonDisable = true
      if (this.getCodeClick(this.brokerLoginForm)) {
        const query = {
          phone: this.brokerLoginForm.mobile_phone.trim(),
          nation: this.brokerLoginForm.nation_code,
          type: this.brokerLoginForm.type,
          source: this.source
        }
        brokerSendVerifyCode(query)
          .then((res) => {
            this.$message.success('Verification code has been sent')
            this.setTimeCount(60)
          })
          .catch(() => {
            this.$message.error('Service is Busy')
            this.setTimeCount(5)
          })
      } else {
        this.$message.error('Wrong moblie phone number, please check!')
        this.isGetCodeButtonDisable = false
        this.isBrokerGetCodeButtonDisable = false
      }
    },
    brokerMobileLogin () {
      this.brokerLoginBtnDisabled = true
      if (this.getCodeClick(this.brokerLoginForm)) {
        const query = {
          nation: this.brokerLoginForm.nation_code,
          phone: this.brokerLoginForm.mobile_phone.trim(),
          code: this.brokerLoginForm.verify_code
        }

        brokerSmsCodeLogin(query)
          .then((res) => {
            setLocalStorage('token', { ...res.token, ...res.user })
            setLocalStorage('user', res.user)
            this.$message.success('Login successfully!')
            this.$router.push({ path: '/broker' })
            this.brokerLoginBtnDisabled = false
          })
          .then(() => {
            awsGetToken().then((res) => {
              this.SetAwsAction(res)
            })
          })
          .catch((e) => {
            setTimeout(() => {
              this.brokerLoginBtnDisabled = false
            }, 2000)
            this.$message.error('Login failed!')
          })
      } else {
        this.$message.error('Wrong moblie phone number, please check!')
        setTimeout(() => {
          this.brokerLoginBtnDisabled = false
        }, 2000)
      }
    }
  }
}
</script>

<style scoped>
.login-container {
    max-height: 100vh;
}
.divider {
    width: 100px;
    height: 6px;
    background: #bc9a41;
    border-radius: 4px;
    margin: 160px auto 0;
}
.title {
    font-size: 36px;
    font-weight: 600;
    color: #163257;
    text-transform: uppercase;
    text-align: center;
    margin-top: 12px;
}
.sms_button{
  height: 46px;
  width: 100%;
}
.subtitle {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-top: 6px;
}
.login-tabs {
    width: 610px;
    margin: 40px auto 0;
}
.login-form {
    width: 600px;
    margin: 40px auto 0;
    padding: 10px 60px 60px 60px;
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    margin-bottom: 160px;
}
.form-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #163257;
    line-height: 22px;
}
.form-title-right {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.25);
}
.margintop20 {
    margin-top: 20px;
}
.margintop32 {
    margin-top: 32px;
}
.sub-btn:disabled {
    background-color: rgb(160, 174, 192);
    border-color: rgb(160, 174, 192);
    cursor: not-allowed;
}
.sub-btn {
    transition: 0.2s;
    height: 56px;
    width: 100%;
    border-radius: 2px;
    background: #163257;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
}
.account-type {
    font-size: 16px;
}
@media only screen and (max-width: 600px) {
    .login-container {
        padding: 10vh 0 0;
    }
    .divider {
        width: 100px;
        height: 6px;
        background: #bc9a41;
        border-radius: 4px;
        margin: 0 auto 0;
    }
    .title {
        font-size: 1.5rem;
        font-weight: 600;
        color: #163257;
        text-transform: uppercase;
        text-align: center;
        margin-top: 2vh;
    }
    .subtitle {
        font-size: 0.75rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        text-align: center;
        margin-top: 1vh;
    }
    .login-form {
        width: 90vw;
        margin: 3vh auto 0;
        padding: 1vw 5vw 8vw 5vw;
        background: #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        margin-bottom: 10vh;
    }
    .form-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1rem;
        font-weight: 400;
        color: #163257;
        line-height: 22px;
    }
    .form-title-right {
        font-size: 0.75rem;
        color: rgba(0, 0, 0, 0.25);
    }

    .margintop20 {
        margin-top: 2vh;
    }
    .margintop32 {
        margin-top: 2vh;
    }
    .sub-btn {
        height: 7vh;
        width: 100%;
        border-radius: 2px;
        background: #163257;
        border: 1px solid rgba(0, 0, 0, 0.15);
        font-size: 1.25rem;
        color: #ffffff;
        cursor: pointer;
    }
    .account-type {
        font-size: 14px;
    }
}
</style>
<style>
.sms-login .ant-input-group .ant-input {
    height: 46px;
    border: 1px solid #eaeaea;
}
.sms-login .ant-input-group-addon {
    height: 46px;
    background-color: #ffffff;
    border: 1px solid #eaeaea;
}
.sms-login .ant-input-lg {
    height: 46px;
    border: 1px solid #eaeaea;
}
.login-form .ant-tabs-bar {
    margin-bottom: 50px;
}
.login-form .ant-tabs-nav .ant-tabs-tab {
    width: 240px;
    text-align: center;
}
@media only screen and (max-width: 600px) {
    .login-form .ant-tabs-nav .ant-tabs-tab {
        width: 40vw;
        text-align: center;
    }
    .login-form .ant-tabs-bar {
    margin-bottom: 2rem;
}
}
</style>
